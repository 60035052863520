import React from "react";
import classNames from 'classnames';
import { CallIcon } from '../../reactcomponents/CallPad';
import HeaderSearch from '../../components/v5/HeaderSearch';
import HeaderDropdown from '../../components/v5/HeaderDropdown';
import HeaderNotificationDropdown from '../../components/v5/HeaderNotificationDropdown';
import PopupNotification from '../../components/v5/PopupNotification';
import
	SipNotification
	,{ SipAgentList, SipTransferNotification}
	from '../../components/v5/SipNotification';
import AgentSocket from './agentsocket';
import SearchSocket from './searchsocket';
import AgentWs from './socket/message';
import {I} from './config.js';
import { getNotificationMessage, getExtQueueType, getNotificationIcon } from "./helpers";
import listenWebsocketConnectionState from './websocketConnectionState';
import onClickOutside from 'react-onclickoutside';
import {
	HEADER_USER_NOTIFICATION,
	HEADER_USER_STATUS,
	HEADER_USER_PROFILE
} from './constants';
import {CancellableSimpleLink, default as CancellableLink} from '../../containers/link';
import Draggable from 'react-draggable';
import {
	WARM_TRANSFER
	,SIP_CALL_CONNECTING
	,SIP_CALL_CONNECTED
	,SIP_CALL_ON_HOLD
} from './callConstants';

class StaticAgentNotification extends React.PureComponent {
	constructor(props){
		super(props);
		this.handleClickOutside = this.handleClickOutside.bind(this);
	}
	handleClickOutside() {
		if (this.props.uiData.openHeaderArrow == HEADER_USER_NOTIFICATION) {
			this.props.onToggleArrow(null);
		}
	}
	render() {
		const {title, messages, onToggleArrow, onShowAllNotification, noticeCount,
			uiData, agentTimezoneOffset, openLastErrandInThread, openErrand,
			openCollaReplyPanel, openMessage, openStatistics, openAccounts, forSidebar, onSetNotificationTab,
			openAnnouncement} = this.props;
		return (
			<div className={classNames(
				"header-notices"
				, {"sidebar-notices": forSidebar}
			)}>
				<HeaderNotificationDropdown
					id={HEADER_USER_NOTIFICATION}
					title={title}
					data={messages}
					onToggleArrow={onToggleArrow}
					onSetNotificationTab={onSetNotificationTab}
					openAnnouncement={openAnnouncement}
					onShowAll={onShowAllNotification}
					noticeCount={noticeCount}
					uiData={uiData}
					agentTimezoneOffset={agentTimezoneOffset}
					openLastErrandInThread={openLastErrandInThread}
					openCollaReplyPanel={openCollaReplyPanel}
					openErrand={openErrand}
					openMessage={openMessage}
					openStatistics={openStatistics}
					openAccounts={openAccounts}
					forSidebar={forSidebar}
				/>
			</div>
		);
	}
};

export const AgentNotification = onClickOutside(StaticAgentNotification);

class StaticAgentMenu extends React.PureComponent {
	constructor(props){
		super(props);
		this.handleClickOutside = this.handleClickOutside.bind(this);
	}
	handleClickOutside() {
		if (this.props.uiData.openHeaderArrow == HEADER_USER_PROFILE) {
			this.props.onToggleArrow(null);
		}
	}
	render(){
		const {
			menu,
			agent,
			uiData,
			menuOnly,
			withStatusChanger,
			onToggleArrow,
			newIMCount,
			onClickPreferences,
			onLinkChange
		} = this.props;
		return (
			<div className="header-user-profile header-notices">
				<HeaderDropdown
					id={HEADER_USER_PROFILE}
					data={menu}
					onToggleArrow={onToggleArrow}
					currentActiveAgent={agent}
					listIconClass={"icon-chevron-right"}
					newIMCount={newIMCount}
					onClickPreferences={onClickPreferences}
					onLinkChange={onLinkChange}
					uiData={uiData}
					withStatusChanger={withStatusChanger}
					menuOnly={menuOnly}
				/>
			</div>
		)
	}
};

export const AgentMenu = onClickOutside(StaticAgentMenu);

class StaticAgentStatusChanger extends React.PureComponent {
	constructor(props){
		super(props);
		this.handleClickOutside = this.handleClickOutside.bind(this);
	}
	handleClickOutside() {
		if (this.props.uiData.openHeaderArrow == HEADER_USER_STATUS && !this.props.embeddedWithMenu) {
			this.props.onToggleArrow(null);
		}
	}
	render(){
		const {menu, agentStatus, agentStatusList, agentStatusById, agentStatusByName, uiData, onToggleArrow, onUpdateStatus, onLoad, newIMCount, embeddedWithMenu} = this.props;
		return (
			<div className="header-user-status">
				<HeaderDropdown
					id={HEADER_USER_STATUS}
					agentStatus={agentStatus}
					agentStatusById={agentStatusById}
					agentStatusByName={agentStatusByName}
					agentStatusList={agentStatusList}
					data={menu}
					onToggleArrow={onToggleArrow}
					onUpdateStatus={onUpdateStatus}
					onLoad={onLoad}
					newIMCount={newIMCount}
					embeddedWithMenu={embeddedWithMenu}
					uiData={uiData} />
			</div>
		)
	}
};

export const AgentStatusChanger = onClickOutside(StaticAgentStatusChanger);

class DoubleClickable extends React.PureComponent {
	constructor(props) {
		super(props);
		this.handleDoubleClick = this.handleDoubleClick.bind(this);
	}
	handleDoubleClick(e) {
		if (typeof this.props.onDoubleClick === 'function') {
			this.props.onDoubleClick(e);
		}
	}
	render() {
		return (
			<span className="sign" onDoubleClick={this.handleDoubleClick}>
				&nbsp;&nbsp;
			</span>
		);
	}
}

const whiteLogo = process.env.CLOUDFRONT_URL + "/img/white-logo.svg";

export default class Header extends React.Component {
	constructor(props) {
		super(props);
		this.handleHomePage = this.handleHomePage.bind(this);
		this.onStatusLoad = this.onStatusLoad.bind(this);
		this.handleKeyUpSearchBox = this.handleKeyUpSearchBox.bind(this);
		this.handleSearchButton = this.handleSearchButton.bind(this);
		this.state = { audioInputListS: null };
	}
	componentDidMount() {
		const encryptedPageId =this.props.pageId
		const decryptionKey = "12345678901234567890123456789012"; 

		this.decryptData(encryptedPageId, decryptionKey)
		.then(decryptedPageId => {
			// Initialize Facebook SDK with decrypted page ID
			console.log("decryptedPageId",decryptedPageId)
		this.loadFacebookSDK(decryptedPageId);			
		})
		.catch(error => {
		  console.error("Error during decryption:", error);
		});
		this.props.onLoad();
		if (window["WebSocket"]) {
			const { callEnabled, onLoadCall } = this.props;
			this.websocketStateSubscription = listenWebsocketConnectionState();
			AgentSocket.Dispatcher = this.props.onWSEvent;
			AgentSocket.Connect();
			AgentWs.Dispatcher = this.props.onWSEvent;
			AgentWs.Connect();
			if (callEnabled && typeof onLoadCall === "function") {
				if (process.env.NODE_ENV !== 'production') {
					console.log("dbg: call enabled, start it");
				}
				onLoadCall(AgentSocket).then(audioInputListS => {
					this.setState({ audioInputListS });
				})
			}
			SearchSocket.Dispatcher = this.props.onWSEvent;
			SearchSocket.Connect();
		} else {
			// TODO let agent know
		}
	}
	loadFacebookSDK = (decryptedPageId) => {
		window.fbAsyncInit = () => {
		  window.FB.init({
			appId      : decryptedPageId,
			cookie     : true, 
			xfbml      : true,
			version    : 'v19.0'
		  });
		}; 
	
		(function(d, s, id){
		   var js, fjs = d.getElementsByTagName(s)[0];
		   if (d.getElementById(id)) return;
		   js = d.createElement(s); js.id = id;
		   js.src = "https://connect.facebook.net/en_US/sdk.js";
		   fjs.parentNode.insertBefore(js, fjs);
		 }(document, 'script', 'facebook-jssdk'));
		 console.log('==========done=========')
	  }
	  async decryptData (encryptedBase64, keyString) {
		// Convert Base64 URL to standard Base64
		const standardBase64 = encryptedBase64.replace(/-/g, '+').replace(/_/g, '/');
	
		try {
			// Convert the base64 string to an ArrayBuffer
			const encryptedData = Uint8Array.from(atob(standardBase64), c => c.charCodeAt(0));
			
			// Assuming the IV is 12 bytes long and is prefixed to the encrypted data
			const iv = encryptedData.slice(0, 12);
			const dataToDecrypt = encryptedData.slice(12);
		
			// Convert the key string to an ArrayBuffer
			const keyBuffer = new TextEncoder().encode(keyString);
		
			// Import the key
			const cryptoKey = await window.crypto.subtle.importKey(
				"raw",
				keyBuffer,
				{ name: "AES-GCM" },
				false, // extractable
				["decrypt"] // possible actions
			);
		
			// Decrypt the data
			const decrypted = await window.crypto.subtle.decrypt(
				{ name: "AES-GCM", iv: iv },
				cryptoKey,
				dataToDecrypt
			);
		
			// Convert the decrypted data to a string
			return new TextDecoder().decode(decrypted);
		} catch (error) {
			console.error("Decryption failed", error);
			return null;
		}
	}
	componentWillUnmount() {
		if (this.props.onUnload) {
			this.props.onUnload();
		}
		if (this.websocketStateSubscription) {
			this.websocketStateSubscription.unsubscribe();
		}
	}
	onStatusLoad() {
		if(this.props.onStatusLoad){
			this.props.onStatusLoad(this.props.aventaEnabled);
		}
	}
	handleSearchButton(e) {
		if(this.props.disableActiveSearch == true){
			this.props.handleSearchButton(e, this.props.searchText);
		}
	}
	handleKeyUpSearchBox(e) {
		if(this.props.disableActiveSearch == false ||
			(this.props.disableActiveSearch == true && e.key == "Enter")){
			this.props.handleKeyUpSearchBox(e, this.props.disableActiveSearch);
		}
	}
	headerActions() {
		const {
				callEnabled
				, callPadPopupShown
				, callStatus
				, onClickCallIcon
				, onClickPreferences
				, onLinkChange
				, onPopAlert
				, onPopupAudioInputSelection
				, onSelectAudioInput
				, selectedAudioInput
				, ...p
			} = this.props
			;
		return <div className="header-actions" >
					<div className="header-search">
					</div>
					<CallIcon
						audioInputListS={this.state.audioInputListS}
						callPadPopupShown={callPadPopupShown}
						hidden={!callEnabled}
						onClick={onClickCallIcon}
						onPopAlert={onPopAlert}
						onPopupAudioInputSelection={onPopupAudioInputSelection}
						onSelectAudioInput={onSelectAudioInput}
						selectedAudioInput={selectedAudioInput}
						status={callStatus}
					/>
					<AgentMenu
						menu={p.menu}
						onToggleArrow={p.onToggleArrow}
						agent={p.agent}
						uiData={p.uiData}
						newIMCount={p.newIMCount}
						onClickPreferences={onClickPreferences}
						onLinkChange={onLinkChange}
					/>
				</div>
	}
	handleHomePage(e) {
		e.preventDefault();
		this.props.onMainView();
	}
	isSearchPage = ()=>{
		let page = this.props.pageAddress;
		if(page !== `${process.env.PATH_PREFIX}v5/search`){
			return false
		}
		return true;
	}
	render() {
		let headerActionBar = this.headerActions();
		if(errandViewOnly == true || (typeof externalqueue !== "undefined" &&
			externalqueue.isExternal == true)){
			headerActionBar=<div />
		}
		let popupMessage = [];
		let message = this.props.notification.uiData.currentPopupMessage;
		let data = this.props.notification.messages;
		for (let i=message.length-1; i>=0; i--) {
			let notifIcon = getNotificationIcon(message[i].msg.text);
			let text = getNotificationMessage(message[i].msg);
			let sum = message[i].sum;
			let popMsg = data[message[i].id];
			if((typeof externalqueue !== "undefined" &&
				externalqueue.isExternal == true) &&
				typeof popMsg !== 'undefined' && popMsg != null &&
				typeof popMsg.msg !== 'undefined' && popMsg.msg != null &&
				typeof popMsg.msg !== 'undefined' &&
				popMsg.msg.linkTo != null){
				if(this.props.eid != 0 && popMsg.msg.linkTo !== this.props.eid){
					continue;
				}
			}
			popupMessage.push(
				<PopupNotification
					data={data[message[i].id]}
					display={true}
					id={message[i].id}
					key={message[i].id}
					message={text}
					icon={notifIcon}
					onClose={this.props.onHidePopupNotification}
					onShowAllNotification={this.props.onShowAllNotification}
					openLastErrandInThread={this.props.OpenLastErrandInThread}
					openErrand={this.props.openErrand}
					openCollaReplyPanel={this.props.openCollaReplyPanel}
					openMessage={this.props.openMessage}
					openStatistics={this.props.openStatistics}
					openAccounts={this.props.openAccounts}
					openAnnouncement={this.props.openAnnouncement}
					sum={sum}
				/>
			)
		}
		const qType = getExtQueueType();
		let makeLogoClickable = true;
		if (qType.length > 0 || this.props.viewSingleErrandOnly) {
			makeLogoClickable = false;
		}
		let showSipNotification = false;
		if(this.props.sipServerUrl != ""){
			showSipNotification = true;
		}
		let showSipTransferNotification = false;
		if(this.props.sipCallCurrentTransferMode == WARM_TRANSFER && (
			this.props.sipCallTransferStatus == SIP_CALL_CONNECTING ||
			this.props.sipCallTransferStatus == SIP_CALL_ON_HOLD ||
			this.props.sipCallTransferStatus == SIP_CALL_CONNECTED)){
			showSipTransferNotification = true;
		}
		let showSipDtmfKeyboard = false;
		if(this.props.sipShowDtmfKeypad == true){
			showSipDtmfKeyboard = true;
		}
		return <header className={"header-slim"}>
				<div className="header-logo">
					{
						makeLogoClickable ?
							<CancellableSimpleLink onClick={this.handleHomePage} title={I("Back to Main")}>
								<img src={whiteLogo} alt="" />
							</CancellableSimpleLink>:
							<img src={whiteLogo} alt="" />
					}
					<DoubleClickable
						onDoubleClick={this.props.onDoubleClickHidden} />
				</div>
				{headerActionBar}
				<div className="pop-up-notification-wrapper">
					{popupMessage}
				</div>
			{ showSipNotification &&
				<Draggable axis="both" bounds="body" defaultPosition={{
					x: 0,
					y: -20
				}}>
					<div className={classNames(
						"sip-incoming-notification-wrapper"
						, {"outgoing": this.props.showSipOutgoingPopup.show}
						)} hidden={!(this.props.showSipIncomingPopup.show || this.props.showSipOutgoingPopup.show)}>
						<SipNotification
							outgoing={this.props.showSipOutgoingPopup.show}
							data={this.props.showSipIncomingPopup.show ? this.props.showSipIncomingPopup : this.props.showSipOutgoingPopup}
							sipCallStatus={this.props.sipCallStatus}
							sipAllowRecord={this.props.sipAllowRecord}
							answerSipCall={this.props.answerSipCall}
							answerTransferCall={this.props.answerTransferCall}
							closeSipCall={this.props.closeSipCall}
							pauseSipCall={this.props.pauseSipCall}
							recordSipCall={this.props.recordSipCall}
							commonApiCall={this.props.commonApiCall}
							eid={this.props.showSipIncomingPopup.show ? this.props.eid : this.props.manualCallEid}
							activeCreatedErrand={this.props.activeCreatedErrand}
							sipCurrentEid={this.props.showSipIncomingPopup.show ? this.props.sipCurrentEid:this.props.manualCallEid}
							errandData={this.props.errandData}
							sipXferRefId={this.props.sipXferRefId}
							sipConn={this.props.sipCallConn}
							sipCallTimer={this.props.sipCallTimer}
							resetSipTimer={this.props.resetSipTimer}
							sipCallIsRecording={this.props.sipCallIsRecording}
							sipHideColdTransfer={this.props.sipHideColdTransfer}
							sipHideWarmTransfer={this.props.sipHideWarmTransfer}
							sipTransferStatus={this.props.sipCallTransferStatus}
							sipSnoopDisplayString={this.props.sipSnoopDisplayString}
							sipChatCallbackEnabled={this.props.sipChatCallbackEnabled}
							toggleExternalKeypad={this.props.toggleExternalKeypad}
							onCallClick={this.props.onApiCallClick}
							onHangupCall={this.props.onApiHangupClick}
							onMaximizeOutgoingCall={this.props.onMaximizeOutgoingCall}
							isCurrentErrand={this.props.sipMakeCallCurrentErrand}
						/>
					</div>
				</Draggable>
			}
			{  this.props.sipCallShowAgentList && !features['sip.allow-external-forward'] &&
				<Draggable axis="both" bounds="body">
					<div className="sip-agent-list-wrapper">
						<SipAgentList
							sipCallStatus={this.props.sipCallStatus}
							sipCallShowAgentList={this.props.sipCallShowAgentList}
							sipCallAgentList={this.props.sipCallAgentList}
							toggleTransferAgentList={this.props.toggleTransferAgentList}
							setTransferMode={this.props.setTransferMode} //not sure this one is needed or not
							handleColdTransferToAgent={this.props.handleColdTransferToAgent}
							handleWarmTransferToAgent={this.props.handleWarmTransferToAgent}
							eid={this.props.showSipIncomingPopup.show ? this.props.eid : this.props.manualCallEid}
							manualDispId={this.props.manualDispId}
							manualEid={this.props.manualEid}
							mCipherKey={this.props.mCipherKey}
							manualCallDispId={this.props.manualCallDispId}
							manualCallEid={this.props.manualCallEid}
							mCallCipherKey={this.props.mCallCipherKey}
							errandData={this.props.errandData}
							sipConn={this.props.sipCallConn}
							sipServerName={this.props.sipServerName}
							outgoing={this.props.showSipOutgoingPopup.show}
						/>
					</div>
				</Draggable>
			}
			{ showSipTransferNotification &&
				<Draggable axis="both" bounds="body"
				defaultPosition={{
					x: 18,
					y: -36
				}}>
					<div className="sip-incoming-notification-wrapper">
						<SipTransferNotification
							data={this.props.showSipIncomingPopup}
							outgoing={this.props.showSipOutgoingPopup.show}
							sipCallStatus={this.props.sipCallStatus}
							sipCallTransferStatus={this.props.sipCallTransferStatus}
							mCipherKey={this.props.mCipherKey}
							handleFinalizeWarmTransferToAgent={this.props.handleFinalizeWarmTransferToAgent}
							manualDispId={this.props.manualDispId}
							manualEid={this.props.manualEid}
							manualCallDispId={this.props.manualCallDispId}
							manualCallEid={this.props.manualCallEid}
							mCallCipherKey={this.props.mCallCipherKey}
							sipAllowRecord={this.props.sipAllowRecord}
							closeXferSipCall={this.props.closeXferSipCall}
							answerTransferCall={this.props.answerTransferCall}
							pauseSipTransferCall={this.props.pauseSipTransferCall}
							commonApiCall={this.props.commonApiCall}
							eid={this.props.eid}
							errandData={this.props.errandData}
							sipXferRefId={this.props.sipXferRefId}
							sipConn={this.props.sipCallConn}
							sipCallIsRecording={this.props.sipCallIsRecording}
							sipShowDtmfKeypad={this.props.sipShowDtmfKeypad}
							toggleDtmfKeypad={this.props.toggleDtmfKeypad}
							sipCallTransferTargetNumber={this.props.sipCallTransferTargetNumber}
							sipCallCurrentTransferMode={this.props.sipCallCurrentTransferMode}
							sipTransferTargetAvatar={this.props.sipTransferTargetAvatar}
							sipCallTransferIsExternal={this.props.sipCallTransferIsExternal}
							isCurrentErrand={this.props.sipMakeCallCurrentErrand}
							currentCipherKey={this.props.currentCipherKey}
						/>
					</div>
				</Draggable>
			}
		</header>;
	}
}
