import AgentSocket from './agentsocket';
import AgentWs from './socket/message';
import {
	CHAT_ERROR,
	CHAT_MESSAGE_SENT,
	evtCHAT_NEW_MESSAGE,
} from '../../redux/constants/constants';

var unsentMessageId = 0;

export function getNewMessageId() {
	if(initialChatData.agentID <= 0) {
		return "INVALID";
	}
	const timestamp = (new Date()).getTime();
	unsentMessageId++;
	const newUnsentMessageId = unsentMessageId;
	return 'a-' + initialChatData.agentID + '-' + timestamp + '-' + newUnsentMessageId;
}


export function sendMessage(chat, text, code, templateId, dispatch) {
	let umid = getNewMessageId()
	, sessionId = chat.sessionId
	, payload = {
		watemplateCode: code,
		message: text,
		sessionId: sessionId,
		id: umid,
		templateId: templateId,
		mcount: chat.messages.length,
	}
	, send = () => {
		let NewSocket = AgentSocket;
		if(features["chat.enable-new-chat"]) {
			NewSocket = AgentWs;
		}
		NewSocket.SendEvent(evtCHAT_NEW_MESSAGE, payload, function(r) {
			if(typeof r !== "undefined"){
				if (!r.error) {
					if(dispatch) {
						dispatch({
							type: CHAT_MESSAGE_SENT,
							payload: r,
							sessionId: sessionId,
						});
					}
					return;
				}
				switch (r.error) {
				case "ERR_TRY_AGAIN":
					setTimeout(send, 3000);
				default:
					if(dispatch) {
						dispatch({
							type: CHAT_ERROR,
							event: evtCHAT_NEW_MESSAGE,
							error: r.error,
							sessionId: sessionId,
							umid: umid,
						});
					}
				}
			}
		});
	}
	;

	send();
	return umid;
}
